import React from "react"


const ScrollRightArrows = () => (
<>
    <div className = "scrldwnarrows" style={{transform: 'rotate(-90deg)'}}>   
        <div className="scrldwnarrow scrldwnarrow-first"></div>
        <div className="scrldwnarrow scrldwnarrow-second"></div>
    </div>
</>
)

export default ScrollRightArrows