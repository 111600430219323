import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/seo"

import TopDark from "../components/Modules/Top/TopDarkRelative"

import Lightbox from "fslightbox-react"
import Slider from "react-slick"

import NextButton from "../components/Elements/Buttons/SliderButtons/NextButton"
import PrevButton from "../components/Elements/Buttons/SliderButtons/PrevButton"
import AddressIcon from "../images/projectspage/address-icon.png"
import CloseDateIcon from "../images/projectspage/iconDate.png"

import AllProjectButton from "../components/Elements/Buttons/RefButtons/RefStyledButtons/RefButton"
import NextArrow from "../components/Elements/Arrows/ScrollRightArrows"
import PreviousArrow from "../components/Elements/Arrows/ScrollLeftArrows"
import Footer from "../components/Modules/FooterPage"

// Gallery functions to move slides with Buttons

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <button
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        right: "130px",
      }}
      onClick={onClick}
    >
      <NextButton />
    </button>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <button
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        left: "50px",
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <PrevButton />
    </button>
  )
}

// Gallery functions to move slides with Buttons

// Start Project template +++++++++++++++++++++++

export default function Project({ data, pageContext }) {
  //SEO THINGS
  const SeoTitle = data.allWpPost.nodes.map(
    ({ seoqueries }) => seoqueries.title
  )
  const SeoDescription = data.allWpPost.nodes.map(
    ({ seoqueries }) => seoqueries.description
  )
  const SeoKeywords = data.allWpPost.nodes.map(
    ({ seoqueries }) => seoqueries.keywords
  )
  //END SEO THINGS

  const h1 = data.allWpPost.nodes.map(({ excerpt }) => (
    <div
      dangerouslySetInnerHTML={{ __html: excerpt }}
      className="post-project-title"
    />
  ))
  // HEADER
  const projectLogo = data.allWpPost.nodes.map(({ companyfeature }) => (
    <img src={companyfeature.logo.sourceUrl} alt="Логотип проекта" />
  ))
  const projectName = data.allWpPost.nodes.map(({ companyfeature }) => (
    <p style={{ fontWeight: "700" }}>{companyfeature.companyname}</p>
  ))
  const companylink = data.allWpPost.nodes.map(({ companyfeature }) => (
    <a href={companyfeature.companylink}>{companyfeature.companylink}</a>
  ))
  const projectAddress = data.allWpPost.nodes.map(({ companyfeature }) => (
    <p style={{ fontWeight: "700" }}>{companyfeature.companyaddress}</p>
  ))
  const closeDate = data.allWpPost.nodes.map(({ companyfeature }) => (
    <p style={{ fontWeight: "700" }}>{companyfeature.closedate}</p>
  ))

  // CONTENT
  const postContent = data.allWpPost.nodes.map(({ content }) => (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  ))

  // NEXT_PREVIOUS POSTS

  const previousPostImg = (
    <div>
      {pageContext.post.previous === null ? (
        <div></div>
      ) : (
        <div>
          <img
            src={
              pageContext.post.previous.nextPreviousFooter.postwrapper.sourceUrl
            }
            alt="Смотрите предыдущий проект"
          />
          <div className="prev-project-text">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageContext.post.previous.nextPreviousFooter.postexcerpt,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
  const nextPostImg = (
    <div>
      {pageContext.post.next === null ? (
        <div></div>
      ) : (
        <div>
          <img
            src={pageContext.post.next.nextPreviousFooter.postwrapper.sourceUrl}
            alt="Смотрите следующий проект"
          />
          <div className="next-project-text">
            <div
              dangerouslySetInnerHTML={{
                __html: pageContext.post.next.nextPreviousFooter.postexcerpt,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
  const previousPageLink = (
    <div>
      {pageContext.post.previous === null ? (
        <div></div>
      ) : (
        <Link to={`/realizovannye_proekty` + pageContext.post.previous.link}>
          <PreviousArrow />
          <div className="prevnext-link">Ранее</div>
        </Link>
      )}
    </div>
  )
  const nextPageLink = (
    <div>
      {pageContext.post.next === null ? (
        <div></div>
      ) : (
        <Link to={`/realizovannye_proekty` + pageContext.post.next.link}>
          <NextArrow />
          <div className="prevnext-link">Далее</div>
        </Link>
      )}
    </div>
  )

  // GALLERY VARS STARTS
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  })
  const [beforeLightboxController, setBeforeLightboxController] = useState({
    toggler: false,
    slide: 0,
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: "slider variable-width",
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          // centerMode: true,
        },
      },
    ],
  }

  const images = data.allWpPost.nodes[0]
  const ImgGallery = images.postgallery.photos.map(
    ({ localFile, id, altText }, index) => (
      <button onClick={() => openLightboxOnSlide(index + 1)}>
        <img
          src={localFile.childImageSharp.resize.src}
          className="postgallery-img"
          key={id}
          alt={altText}
        />
      </button>
    )
  )
  const Sources = images.postgallery.photos.map(({ localFile, id }) => (
    <img src={localFile.childImageSharp.original.src} key={id} alt="" />
  ))

  // IMAGES BEFORE

  const GalleryBeforeTitle =
    images.postgallerybefore.gallerytitlebefore === null ? (
      <span></span>
    ) : (
      <h4 className="gallerybeforetitle">
        {images.postgallerybefore.gallerytitlebefore}
      </h4>
    )
  const ImgGalleryBefore =
    images.postgallerybefore.photosbefore === null ? (
      <span></span>
    ) : (
      images.postgallerybefore.photosbefore.map(
        ({ localFile, id, altText }, index) => (
          <button onClick={() => openBeforeLightboxOnSlide(index + 1)}>
            <img
              src={localFile.childImageSharp.resize.src}
              className="postgallery-img"
              key={id}
              alt={altText}
            />
          </button>
        )
      )
    )
  const SourcesBefore =
    images.postgallerybefore.photosbefore === null ? (
      <p></p>
    ) : (
      images.postgallerybefore.photosbefore.map(({ localFile, id }) => (
        <img src={localFile.childImageSharp.original.src} key={id} alt="" />
      ))
    )

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }

  function openBeforeLightboxOnSlide(number) {
    setBeforeLightboxController({
      toggler: !beforeLightboxController.toggler,
      slide: number,
    })
  }
  // END GALLERY VARS

  return (
    <>
      <Seo
        title={SeoTitle}
        description={SeoDescription}
        keywords={SeoKeywords.toString()}
      />
      <Container fluid>
        <Row>
          <Col>
            <TopDark />
          </Col>
        </Row>
        <Row className="post-project-title-header">
          <Col>{h1}</Col>
        </Row>
        {/* PROJECT FEATURE HEADER START  */}
        <Row className="post-project-info-header">
          <Col xl={1} className="company-logo-area">
            {projectLogo}
          </Col>
          <Col xl={11}>
            <p>компания:</p>
            {projectName}
            {companylink}
          </Col>
          <Col style={{ display: "grid", alignContent: "center" }}>
            <img src={AddressIcon} alt="" />
          </Col>
          <Col>
            <p>адрес:</p>
            {projectAddress}
          </Col>
          <Col style={{ display: "grid", alignContent: "center" }}>
            <img src={CloseDateIcon} alt="" />
          </Col>
          <Col>
            <p>срок ввода в эксплуатацию:</p>
            {closeDate}
          </Col>
        </Row>
        {/* PROJECT FEATURE HEADER END  */}

        {/* GALLERY ROW  STARTS */}
        <Row>
          <Col>
            <Lightbox
              toggler={lightboxController.toggler}
              slide={lightboxController.slide}
              sources={Sources}
            />
            <Slider {...settings}>{ImgGallery}</Slider>
          </Col>
        </Row>

        {/* GALLERY ROW ENDS  */}

        {/* GALLERY BEFORE ROW  STARTS */}
        <Row style={{ position: "relative", top: "2em", paddingBottom: "1em" }}>
          <Col>
            <Lightbox
              toggler={beforeLightboxController.toggler}
              slide={beforeLightboxController.slide}
              sources={SourcesBefore}
            />
            {GalleryBeforeTitle}
            <Slider {...settings}>{ImgGalleryBefore}</Slider>
          </Col>
        </Row>

        {/* GALLERY BEFORE ROW ENDS  */}

        <Row className="post-content-container">
          <Col>{postContent}</Col>
        </Row>
        <Row>
          <Col
            className="projects-btn"
            style={{ display: "grid", justifyContent: "center" }}
          >
            <Link to="/projects">
              <AllProjectButton>ВСЕ ПРОЕКТЫ</AllProjectButton>
            </Link>
          </Col>
        </Row>

        <Row className="project-page-bottom">
          <Col xl={5} lg={6} md={6} style={{ paddingLeft: "0" }}>
            {previousPostImg}
            <Row className="project-page-bottom-link-prev">
              {previousPageLink}
            </Row>
          </Col>
          <Col xl={7} lg={6} md={6} style={{ paddingRight: "0" }}>
            {nextPostImg}
            <Row className="project-page-bottom-link-next">{nextPageLink}</Row>
          </Col>
        </Row>

        <Row>
          <Col style={{ padding: 0 }}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpPost(sort: { fields: date }, filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        excerpt
        title
        companyfeature {
          logo {
            sourceUrl
          }
          companyname
          companylink
          companyaddress
          closedate
        }
        content
        postgallery {
          photos {
            localFile {
              childImageSharp {
                resize(height: 300) {
                  src
                }
                original {
                  src
                }
              }
            }
            id
            altText
          }
        }
        postgallerybefore {
          gallerytitlebefore
          photosbefore {
            localFile {
              childImageSharp {
                resize(height: 300) {
                  src
                }
                original {
                  src
                }
              }
            }
            id
            altText
          }
        }
        seoqueries {
          description
          keywords
          title
        }
      }
    }
  }
`
