import React from "react"


const ScrollLeftArrows = () => (
<>
    <div className = "scrldwnarrows">   
        <div className="scrldwnarrow scrldwnarrow-first"></div>
        <div className="scrldwnarrow scrldwnarrow-second"></div>
    </div>
</>
)

export default ScrollLeftArrows